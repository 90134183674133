<template>
  <div class="search">
    <public-header />
    <div class="banner main">
      <div class="container">
        <v-breadcrumbs :items="breadCrumb" class="main-breadcrumb">
          <template v-slot:divider>
            <span class="icon icon-arrow-next breadCrumb-arrow"></span>
          </template>
        </v-breadcrumbs>
      </div>
    </div>
    <main>
      <div class="container search-wrapper">
        <h1 class="search-wrapper-title">{{ $t('interactiveSearchInformation') }}</h1>
        <v-form ref="form" @submit.prevent="getSearchData" class="search-wrapper-search">
          <v-autocomplete
            :placeholder="$t('searchFromOfficialStatistics')"
            hide-details
            background-color="white"
            :items="data"
            outlined
            @keyup="searchBykeyup"
            @change="getSearchData"
            :no-filter="true"
            :loading="isLoading"
            v-model="searchInput"
            cache-items
            hide-no-data
          >
            <template slot="item" slot-scope="data">
              <div>
                <span class="icon icon-search"></span>
                <span class="ml-4 search-wrapper-search-values">{{ data.item.text }}</span>
              </div>
            </template>
          </v-autocomplete>
          <button @click="getSearchData" type="summbit">{{ $t('search') }}</button>
        </v-form>
        <div class="search-wrapper-results">
          <div class="search-wrapper-results-head">
            <h3>{{ $t('searchResults') }}</h3>
            <p>{{ $t('numberPackagesFound') }}: {{ allResults }}</p>
          </div>
          <div class="search-wrapper-results-item" v-for="item in resultsData" :key="item.id">
            <div class="search-wrapper-results-item-left">
              <div>
                <div v-for="links in item.breadCrumb" :key="links.text">
                  <p :class="{ longText: links.text.split(' ').length > 4 }">{{ links.text }}</p>
                  <span class="icon icon-arrow-next"></span>
                </div>
              </div>
              <router-link :to="`/reports-filed/${item.id}/meta-data`">{{ item.name }}</router-link>
              <span class="code-msft">{{ item.sdmxCode }}</span>
            </div>
            <div class="search-wrapper-results-item-right">
              <div>
                <button @click="downloadFile('xlsx', item.id)" class="button-file-download">
                  Excel
                </button>
                <button @click="downloadFile('pdf', item.id)" class="button-file-download">
                  PDF
                </button>
                <button @click="downloadFile('csv', item.id)" class="button-file-download">
                  CSV
                </button>
                <button @click="downloadFile('json', item.id)" class="button-file-download">
                  JSON (API)
                </button>
                <button @click="downloadFile('xml', item.id)" class="button-file-download">
                  XML (API)
                </button>
              </div>
              <p>
                {{ $t('download') }}: {{ item.downloaded }}
                <span class="icon icon-download-black"></span></p>
            </div>
          </div>
          <div class="search-pagination">
          <p v-if="currentlang == 'uz'">
            {{ thisPage }} sahifa
            {{ Math.round(allPage / countList) == 0 ? 1 : Math.round(allPage / countList) }}
            dan
          </p>
          <p v-if="currentlang == 'ru'">
            Страница {{ thisPage }} из
            {{ Math.round(allPage / countList) == 0 ? 1 : Math.round(allPage / countList) }}
          </p>
          <p v-if="currentlang == 'en'">
            Page {{ thisPage }} of
            {{ Math.round(allPage / countList) == 0 ? 1 : Math.round(allPage / countList) }}
          </p>
          <div>
            <p>{{ $t('page') }}:</p>
            <input
              @change="changeCount"
              type="text"
              v-model="thisPage"
              :placeholder="thisPage">
            <button @click="oldPage" :disabled="previousPageButton">
              <span class="icon icon-arrow-old"></span>
            </button>
            <button @click="nextPage" :disabled="nextPageButton">
              <span class="icon icon-arrow-new"></span>
            </button>
          </div>
        </div>
        </div>
      </div>
    </main>
    <home-footer />
  </div>
</template>

<script>
import PublicHeader from '@/components/PublicHeader';
import HomeFooter from '@/components/HomeFooter';
import Api from '@/api/Api';

export default {
  name: 'SearchView',
  components: {
    PublicHeader,
    HomeFooter,
  },
  data() {
    return {
      breadCrumb: [
        {
          text: this.$t('main'),
          disabled: false,
          link: true,
          exact: true,
          to: {
            path: '/',
          },
        },
        {
          text: this.$t('searchResults'),
          disabled: true,
          to: {
            path: '/',
          },
        },
      ],
      resultsData: [],
      isLoading: false,
      searchInput: '',
      notComplect: '',
      allResults: 0,
      data: [],
      countList: 20,
      allPage: 0,
      thisPage: 1,
      nextPageButton: false,
      previousPageButton: true,
      currentlang: localStorage.getItem('language'),
    };
  },
  methods: {
    async getSearchData(query) {
      let searchValue = '';
      if (typeof query === 'string') {
        if (query !== null && query.length !== 0) {
          searchValue = query;
        }
      } else {
        searchValue = this.notComplect;
      }
      if (searchValue) {
        const { data } = await Api.getOpenInformationData(
          { search: searchValue, size: this.countList, page: this.thisPage },
        );
        this.allPage = data.count;
        if (!data.previous) {
          this.previousPageButton = true;
        } else {
          this.previousPageButton = false;
        }
        if (!data.next) {
          this.nextPageButton = true;
        } else {
          this.nextPageButton = false;
        }
        this.data = [];
        if (data.count === 0) {
          this.allResults = 0;
          this.resultsData = [];
        } else {
          this.resultsData = data.results.map((item) => {
            const response = {
              name: item.name,
              sdmxCode: item.code,
              downloaded: item.downloaded,
              breadCrumb: [
                {
                // eslint-disable-next-line no-nested-ternary
                  text: item.parent.parent ? item.parent.parent.parent ? item.parent.parent.parent.name : '' : '',
                },
                {
                  text: item.parent ? item.parent.parent.name : '',
                },
                {
                  text: item ? item.parent.name : '',
                },
              ],
              id: item.id,
            };
            return response;
          });
          this.allResults = data.count;
          this.searchInput = searchValue;
        }
        this.data.unshift({
          text: searchValue,
          disabled: true,
        });
        this.searchInput = searchValue;
      }
    },
    async searchBykeyup(event) {
      const value = event.target ? event.target.value : event;
      this.notComplect = value;
      this.isLoading = true;
      localStorage.setItem('search', value);
      if (value.length >= 3) {
        const { data } = await Api.getOpenInformationData({ search: value });
        this.data = data.results.map((item) => ({
          text: item.name,
        }));
      }
      this.isLoading = false;
    },
    oldPage() {
      this.thisPage -= 1;
      this.getSearchData(this.notComplect);
    },
    nextPage() {
      this.thisPage = Number(this.thisPage) + 1;
      this.getSearchData(this.notComplect);
    },
    changeCount(e) {
      this.thisPage = e.target.value;
      this.getSearchData(this.notComplect);
    },
    async downloadFile(type, id) {
      const params = {
        download_format: type,
      };
      const { data } = await Api.downloadTableFile(id, params);
      const { file } = data;
      if (file) {
        const link = document.createElement('a');
        link.href = file;
        link.target = '_blank';
        link.click();
        link.remove();
      } else {
        alert(this.$t('fileNotFound'));
      }
    },
  },
  mounted() {
    this.getSearchData(localStorage.getItem('search'));
  },
};
</script>

<style src="./SearchView.scss" lang="scss"></style>
